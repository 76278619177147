/* Global value object */
export const GlobalValues = {
    // Global configuration
    appName: "Krystal Allied Services",
    appNameShort: "KAS",

    // API Keys
    // googleMapsApiKey: "AIzaSyDSuSDm9k3VWIJbvfHB2V4Z_VcOBTE0QH8",
    googleMapsApiKey: "AIzaSyAeYsBx_MLIVrl7pgV2mVuw-N5VrN1N344",

    // Role constants
    superAdministratorRole: {
        id: 1,
        name: "Super Administrator"
    },
    operationsAdministratorRole: {
        id: 2,
        name: "Operations Administrator"
    },
    financeAdministratorRole: {
        id: 3,
        name: "Finance Administrator"
    },
    purchaseAdministratorRole: {
        id: 4,
        name: "Purchase Administrator"
    },
    projectManagerRole: {
        id: 5,
        name: "Project Manager"
    },
    organizationAdministratorRole: {
        id: 6,
        name: "Organization Administrator"
    },
    supervisorRole: {
        id: 7,
        name: "Supervisor"
    },
    employeeRole: {
        id: 8,
        name: "Employee"
    },
    customerAdministratorRole: {
        id: 9,
        name: "Customer Administrator"
    },
    customerEndUserRole: {
        id: 10,
        name: "Customer End User"
    },

    // Organization type constants
    superAdministrationType: {
        id: 1,
        name: "Super Administration"
    },
    specialOrganizationType: {
        id: 2,
        name: "Special Organization"
    },
    organizationType: {
        id: 3,
        name: "Organization"
    },
    customerType: {
        id: 4,
        name: "Customer"
    },

    // Checklist item type constants
    booleanChecklistItemType: "BOOLEAN-RESPONSE",
    freeResponseChecklistItemType: "FREE-RESPONSE",

    // Base URLs
    // dashboardBaseUrl: "http://localhost:3000/",
    // apiBaseUrl: "http://192.168.24.45:8000/api/",

    dashboardBaseUrl: "https://app.intellbuilding.com/",
    apiBaseUrl: 'https://api.intellbuilding.com/api/',

    // Authentication URLs
    loginUrl: "users/custom-login/",
    logoutUrl: "users/custom-logout/",

    // Organization URLs
    organizationCreateUrl: "organization/",
    organizationListUrl: "organization/list/",
    organizationListFilterUrl: "organization/list-filter/",

    // Service URLs
    serviceCreateUrl: "service/",
    serviceListUrl: "service/list/",
    serviceListFilterUrl: "service/list-filter/",

    // Employee Category URLs
    employeeCategoryCreateUrl: "employeecategory/",
    employeeCategoryListUrl: "employeecategory/list/",
    employeeCategoryListFilterUrl: "employeecategory/list-filter/",

    // Checklist Category URLs
    checklistCategoryCreateUrl: "checklist/checklist-category/",
    checklistCategoryListUrl: "checklist/checklist-category/list/",
    checklistCategoryListFilterUrl: "checklist/checklist-category/list-filter/",

    // Checklist Item URLs
    checklistItemCreateUrl: "checklist/checklist-item/",
    checklistItemListUrl: "checklist/checklist-item/list/",
    checklistItemListFilterUrl: "checklist/checklist-item/list-filter/",

    // Project URLs
    projectCreateUrl: "project/",
    projectListUrl: "project/list/",
    projectListFilterUrl: "project/list-filter/",

    // Zone URLs
    zoneCreateUrl: "zone/",
    zoneListUrl: "zone/list/",
    zoneListFilterUrl: "zone/list-filter/",

    // Service Provider Type URLs
    serviceProviderTypeCreateUrl: "serviceprovider/service-provider-type/",
    serviceProviderTypeListUrl: "serviceprovider/list-service-provider-type/",
    serviceProviderTypeListFilterUrl: "serviceprovider/list-service-provider-type-filter/",

    // Service Provider URLs
    serviceProviderCreateUrl: "serviceprovider/",
    serviceProviderListUrl: "serviceprovider/list/",
    serviceProviderListFilterUrl: "serviceprovider/list-filter/",

    // User URLs
    userCreateUrl: "users/",
    userListUrl: "users/list/",
    userListFilterUrl: "users/list-filter/",

    // User Employee Category URLs
    userEmployeeCategoryCreateUrl: "users/user-employee-category/",

    // Role URLs
    roleListUrl: "organization/list-organization-type-role/",

    // Holiday
    holidayListUrl: "reporting/holiday-report/",

    // Project Organization URLs
    projectOrganizationCreateUrl: "project/project-organization/",
    projectOrganizationListUrl: "project/project-organization/list/",
    projectOrganizationListFilterUrl: "project/project-organization/list-filter/",

    // Project Management URLs
    projectManagementCreateUrl: "project/project-management/",

    // Project Organization Service URLs
    projectOrganizationServiceCreateUrl: "project/project-organization-service/",
    projectOrganizationServiceListFilterUrl: "project/project-organization-service/list-filter/",

    // Project Zone URLs
    projectZoneCreateUrl: "project/project-zone/",
    projectZoneListFilterUrl: "project/project-zone/list-filter/",

    // Project Zone Scan Time
    projectZoneScanTimeCreateUrl: "project/project-zone-scantime/",
    projectZoneScanTimeListUrl: "project/project-zone-scantime/list/",

    // Project Zone Checklist Item URLs
    projectZoneChecklistItemCreateUrl: "project/project-zone-checklist-item/",

    // ProjectOrganizationManagement URLs
    assignedProjectOrganizationListUrl: "project/assigned-project-organization/list/",
    assignedProjectOrganizationManagementListFilterUrl: "project/assigned-project-organization-management/list-filter/",
    assignProjectOrganizationManagementUrl: "project/assign-project-organization-management/",

    // ProjectOrganizationServiceUser URLs
    assignedProjectOrganizationServiceListUrl: "project/assigned-project-organization-service/list/",
    assignedProjectOrganizationServiceUserListFilterUrl: "project/assigned-project-organization-service-user/list-filter/",
    assignProjectOrganizationServiceUserUrl: "project/assign-project-organization-service-user/",

    // Project Manager URLs
    projectManagerProjectZoneListUrl: "project/project-manager-project-zone/list/",
    projectManagerProjectZoneListMultiUrl: "project/project-manager-project-zone-multi/list/",
    projectManagerProjectZonesListMultiUrl: "project/project-manager-project-zone-service/list/",
    projectManagerProjectListFilterUrl: "project/project-manager-project/list-filter/",
    projectManagerDeleteServiceUserListFilterUrl: "project/project-manager-delete-service-user/list-filter/",
    projectManagerScanListFilterUrl: "project/project-manager-scan/list-filter/",
    projectManagerMissedScanFilterUrl: "scan/missed-scan-list-filter/",
    projectManagerScansListFilterUrl: "project/project-manager-scans/list-filter/",
    projectManagerServiceScansListFilterUrl: "project/project-manager-project-service/list/",
    projectManagerScanDetailUrl: "project/project-manager-scan-detail/",
    projectManagerTicketListFilterUrl: "project/project-manager-ticket/list-filter/",
    projectManagerTicketDetailUrl: "project/project-manager-ticket-detail/",
    projectManagerAttendanceListFilterUrl: "project/project-manager-attendance/list-filter/",
    projectManagerAllAssetMaintenanceLogsUrl: "assetservice/list-asset-maintenance-log-all-filter/",

    // Reporting URLs
    reportingScanReport: "reporting/scan-report/",
    reportingScanReportExport: "reporting/scan-report-export/",
    reportingAttendanceReport: "reporting/attendance-report/",
    scanComparisonReport: "project/project-zone-scantime-compare-list/",

    //asset type
    manageAssetTypeUrl: "asset/asset-type/",
    assetTypeListFilter: "asset/list-asset-type-filter/",
    assetTypeDropdownList: "asset/list-asset-type/",

    //asset
    manageAssetUrl: "asset/",
    assetListFilter: "asset/list-filter/",
    assetDropdownList: "asset/list/",
    downloadAssetFile: "asset/download-asset-file/",

    //asset service type
    manageAssetServiceTypeUrl: "assetservice/asset-service-type/",
    assetServiceTypeListFilter: "assetservice/list-asset-service-type-filter/",
    assetServiceTypeDropdownList: "assetservice/list-asset-service-type/",

    //asset service provider
    manageAssetServiceProviderUrl: "assetservice/asset-service-provider/",
    assetServiceProviderListFilter: "assetservice/list-asset-service-provider-filter/",
    assetServiceProviderDropdownList: "assetservice/list-asset-service-provider/",

    //asset service provider contact
    manageAssetServiceProviderContactUrl: "assetservice/asset-service-provider-contact/",
    assetServiceProviderContactListFilter: "assetservice/list-asset-service-provider-contact-filter/",
    assetServiceProviderContactDropdownList: "assetservice/list-asset-service-provider-contact/",

    //asset service provider feedback
    manageAssetServiceProviderFeedbackUrl: "assetservice/asset-service-provider-feedback/",
    assetServiceProviderFeedbackListFilter: "assetservice/list-asset-service-provider-feedback-filter/",
    assetServiceProviderFeedbackDropdownList: "assetservice/list-asset-service-provider-feedback/",

    //asset maintenance log
    manageAssetMaintenanceLogUrl: "assetservice/asset-maintenance-log/",
    assetMaintenanceLogListFilter: "assetservice/list-asset-maintenance-log-filter/",
    assetMaintenanceLogDropdownList: "assetservice/list-asset-maintenance-log/",

    //asset maintenance file
    manageAssetMaintenanceFileUrl: "assetservice/asset-maintenance-file/",
    assetMaintenanceFileListFilter: "assetservice/list-asset-maintenance-file-filter/",
    assetMaintenanceFileDropdownList: "assetservice/list-asset-maintenance-file/",

    //asset maintenance notification
    manageAssetMaintenanceNotificationUrl: "assetservice/asset-notification/",
    assetMaintenanceNotificationListFilter: "assetservice/list-asset-notification-filter/",
    assetMaintenanceNotificationDropdownList: "assetservice/list-asset-notification/",

    //asset amc
    manageAssetAmcUrl: "asset/amc/",
    assetAmcListFilter: "asset/list-amc-filter/",
    assetAmcDropdownList: "asset/list-amc/",

    //amc
    amcListFilterForProjects: "asset/list-amc-filter-for-projects/",
    groupAmcListFilterForProjects: "group/amc-dashboard/",

    //asset group
    manageAssetGroupUrl: "group/",
    assetGroupListFilter: "group/list-filter/",
    assetGroupDropdownList: "group/list/",

    //asset group amc
    manageAssetGroupAmcUrl: "group/amc/",
    assetGroupAmcListFilter: "group/amc/list-filter/",
    assetGroupAmcDropdownList: "group/amc/list/",

    //asset group maintenance log
    manageAssetGroupMaintenanceLogUrl: "group/maintenance-log/",
    assetGroupMaintenanceLogListFilter: "group/maintenance-log/list-filter/",
    assetGroupMaintenanceLogDropdownList: "group/maintenance-log/list/",

    //dlp
    dlpListFilter: "asset/list-dlp-filter/",

    //project maintenance report 
    projectMaintenanceReportListFilter: "asset/maintenance-report-list-filter/",
    projectMaintenanceManageReport: "asset/maintenance-report/",
    projectMaintenanceReportStatus: "asset/maintenance-report-update/"
};

export const ErrorMessages = {
    /* Generic messages */
    required: "This field can not be blank.",
    maxLength: "This value must be no more than ",
    minLength: "This value must be no less than ",
    invalid: "This value appears to be invalid.",
    invalidDate: "This value is not a valid date.",
    invalidTime: "This value is not a valid time.",
    invalidLocation: "Please select a location from the drop-down list of options.",
    fileRequired: "You must select a file to upload.",
    reportingDateRangeNotSelected: "You must select a date range to run this report.",
    dateRangeNotSelected: "You must select all date ranges in the filter.",
    missingRequiredFields: "Please fill in all required fields.",
    invalidNumberOfRecordsMSS: "The number of Minimum Supervisor selected is not matching.",
    invalidNumberOfRecordsMES: "The number of Minimum Employee selected is not matching.",
    minValue: "This value must be greater than or equal to ",
    maxValue: "This value must be less than or equal to ",
    invalidEmail: "Please enter a valid email address.",
    invalidPhoneNumber: "Please enter a valid phone number.",

    /* Server messages */
    serverErrorPrefix: "Server: ",
    severForbiddenError: "You do not have the necessary authorization to make this request.",
    serverNotFoundError: "This data either does not exist or you are not authorized to view it.",
    serverRequestError: "The server is not responding. Please check your internet connection or try again later.",
    serverGenericError: "Something went wrong while attempting to process your request. Please try again later."
};

export const SuccessMessages = {
    /* Generic messages */
    success: "Success!",
    successMessage: "Your request was successfully processed.",

    /* Server messages */
    serverSuccessPrefix: "Server: ",
    serverSuccessMessage: "Your request was successfully processed.",

    /* Custom messages */
    instituteCreated: "Institute created successfully.",
    instituteUpdated: "Institute updated successfully.",
    instituteDeleted: "Institute deleted successfully.",

    userCreated: "User created successfully.",
    userUpdated: "User updated successfully.",
    userDeleted: "User deleted successfully.",

    // asset type
    assetTypeCreated: "Asset type created successfully.",
    assetTypeUpdated: "Asset type updated successfully.",
    assetTypeDeleted: "Asset type deleted successfully.",

    // asset make
    assetMakeCreated: "Asset make created successfully.",
    assetMakeUpdated: "Asset make updated successfully.",
    assetMakeDeleted: "Asset make deleted successfully.",

    // asset
    assetCreated: "Asset created successfully.",
    assetUpdated: "Asset updated successfully.",
    assetDeleted: "Asset deleted successfully.",

    // asset service type
    assetServiceTypeCreated: "Asset service type created successfully.",
    assetServiceTypeUpdated: "Asset service type updated successfully.",
    assetServiceTypeDeleted: "Asset service type deleted successfully.",

    // asset service provider
    assetServiceProviderCreated: "Asset service provider created successfully.",
    assetServiceProviderUpdated: "Asset service provider updated successfully.",
    assetServiceProviderDeleted: "Asset service provider deleted successfully.",

    // asset service provider contact
    assetServiceProviderContactCreated: "Asset service provider contact created successfully.",
    assetServiceProviderContactUpdated: "Asset service provider contact updated successfully.",
    assetServiceProviderContactDeleted: "Asset service provider contact deleted successfully.",

    // asset service provider feedback
    assetServiceProviderFeedbackCreated: "Asset service provider feedback created successfully.",
    assetServiceProviderFeedbackUpdated: "Asset service provider feedback updated successfully.",
    assetServiceProviderFeedbackDeleted: "Asset service provider feedback deleted successfully.",

    // asset maintenance log
    assetMaintenanceLogCreated: "Asset maintenance log created successfully.",
    assetMaintenanceLogUpdated: "Asset maintenance log updated successfully.",
    assetMaintenanceLogDeleted: "Asset maintenance log deleted successfully.",

    // asset maintenance file
    assetMaintenanceFileCreated: "Asset maintenance file created successfully.",
    assetMaintenanceFileUpdated: "Asset maintenance file updated successfully.",
    assetMaintenanceFileDeleted: "Asset maintenance file deleted successfully.",

    // asset maintenance notification
    assetMaintenanceNotificationCreated: "Asset maintenance notification created successfully.",
    assetMaintenanceNotificationUpdated: "Asset maintenance notification updated successfully.",
    assetMaintenanceNotificationDeleted: "Asset maintenance notification deleted successfully.",

    // asset amc
    assetAmcCreated: "Asset amc created successfully.",
    assetAmcUpdated: "Asset amc updated successfully.",
    assetAmcDeleted: "Asset amc deleted successfully.",

    // asset group
    assetGroupCreated: "Asset group created successfully.",
    assetGroupUpdated: "Asset group updated successfully.",
    assetGroupDeleted: "Asset group deleted successfully."
};

/* Util method to convert file into base64 */
export const toBase64 = file => {
    if (file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
        });
    }
    return null;
};

/* Util method to download a file */
export const downloadFile = (fileToDownload, fileName) => {
    const anchorElement = document.createElement("a");
    anchorElement.href = fileToDownload;
    anchorElement.download = fileName;
    anchorElement.target = "_blank";
    document.body.appendChild(anchorElement);
    anchorElement.click();
    document.body.removeChild(anchorElement);
};

/* Util method to find an array intersection */
export const findArrayIntersection = (arr1, arr2) => {
    const set = new Set(arr1);
    return arr2.filter(item => set.has(item));
};

/* Util method to change the body attribute */
export const changeBodyAttribute = (attribute, value) => {
    if (document.body) document.body.setAttribute(attribute, value);
};

/* Utility method to get all current status */
export const getCurrentStatus = () => {
    return [
        {
            label: "Dispatched",
            value: "Dispatched"
        },
        {
            label: "Delivered",
            value: "Delivered"
        },
        {
            label: "In-Stock",
            value: "In-Stock"
        },
        {
            label: "In-Use",
            value: "In-Use"
        },
        {
            label: "Retired",
            value: "Retired"
        }
    ];
};

export const getBooleanOptions = () => {
    return [
        {
            label: "Yes",
            value: true
        },
        {
            label: "No",
            value: false
        }
    ];
};

export const getTimeOptions = () => {
    return [
        { label: "12:00 AM", value: "00:00:00" },
        { label: "12:30 AM", value: "00:30:00" },
        { label: "1:00 AM", value: "01:00:00" },
        { label: "1:30 AM", value: "01:30:00" },
        { label: "2:00 AM", value: "02:00:00" },
        { label: "2:30 AM", value: "02:30:00" },
        { label: "3:00 AM", value: "03:00:00" },
        { label: "3:30 AM", value: "03:30:00" },
        { label: "4:00 AM", value: "04:00:00" },
        { label: "4:30 AM", value: "04:30:00" },
        { label: "5:00 AM", value: "05:00:00" },
        { label: "5:30 AM", value: "05:30:00" },
        { label: "6:00 AM", value: "06:00:00" },
        { label: "6:30 AM", value: "06:30:00" },
        { label: "7:00 AM", value: "07:00:00" },
        { label: "7:30 AM", value: "07:30:00" },
        { label: "8:00 AM", value: "08:00:00" },
        { label: "8:30 AM", value: "08:30:00" },
        { label: "9:00 AM", value: "09:00:00" },
        { label: "9:30 AM", value: "09:30:00" },
        { label: "10:00 AM", value: "10:00:00" },
        { label: "10:30 AM", value: "10:30:00" },
        { label: "11:00 AM", value: "11:00:00" },
        { label: "11:30 AM", value: "11:30:00" },
        { label: "12:00 PM", value: "12:00:00" },
        { label: "12:30 PM", value: "12:30:00" },
        { label: "1:00 PM", value: "13:00:00" },
        { label: "1:30 PM", value: "13:30:00" },
        { label: "2:00 PM", value: "14:00:00" },
        { label: "2:30 PM", value: "14:30:00" },
        { label: "3:00 PM", value: "15:00:00" },
        { label: "3:30 PM", value: "15:30:00" },
        { label: "4:00 PM", value: "16:00:00" },
        { label: "4:30 PM", value: "16:30:00" },
        { label: "5:00 PM", value: "17:00:00" },
        { label: "5:30 PM", value: "17:30:00" },
        { label: "6:00 PM", value: "18:00:00" },
        { label: "6:30 PM", value: "18:30:00" },
        { label: "7:00 PM", value: "19:00:00" },
        { label: "7:30 PM", value: "19:30:00" },
        { label: "8:00 PM", value: "20:00:00" },
        { label: "8:30 PM", value: "20:30:00" },
        { label: "9:00 PM", value: "21:00:00" },
        { label: "9:30 PM", value: "21:30:00" },
        { label: "10:00 PM", value: "22:00:00" },
        { label: "10:30 PM", value: "22:30:00" },
        { label: "11:00 PM", value: "23:00:00" },
        { label: "11:30 PM", value: "23:30:00" }
    ];
};

export const getTimeOptions24Hour = () => {
    return [
        { label: "00:00:00", value: "00:00:00" },
        { label: "00:30:00", value: "00:30:00" },
        { label: "01:00:00", value: "01:00:00" },
        { label: "01:30:00", value: "01:30:00" },
        { label: "02:00:00", value: "02:00:00" },
        { label: "02:30:00", value: "02:30:00" },
        { label: "03:00:00", value: "03:00:00" },
        { label: "03:30:00", value: "03:30:00" },
        { label: "04:00:00", value: "04:00:00" },
        { label: "04:30:00", value: "04:30:00" },
        { label: "05:00:00", value: "05:00:00" },
        { label: "05:30:00", value: "05:30:00" },
        { label: "06:00:00", value: "06:00:00" },
        { label: "06:30:00", value: "06:30:00" },
        { label: "07:00:00", value: "07:00:00" },
        { label: "07:30:00", value: "07:30:00" },
        { label: "08:00:00", value: "08:00:00" },
        { label: "08:30:00", value: "08:30:00" },
        { label: "09:00:00", value: "09:00:00" },
        { label: "09:30:00", value: "09:30:00" },
        { label: "10:00:00", value: "10:00:00" },
        { label: "10:30:00", value: "10:30:00" },
        { label: "11:00:00", value: "11:00:00" },
        { label: "11:30:00", value: "11:30:00" },
        { label: "12:00:00", value: "12:00:00" },
        { label: "12:30:00", value: "12:30:00" },
        { label: "13:00:00", value: "13:00:00" },
        { label: "13:30:00", value: "13:30:00" },
        { label: "14:00:00", value: "14:00:00" },
        { label: "14:30:00", value: "14:30:00" },
        { label: "15:00:00", value: "15:00:00" },
        { label: "15:30:00", value: "15:30:00" },
        { label: "16:00:00", value: "16:00:00" },
        { label: "16:30:00", value: "16:30:00" },
        { label: "17:00:00", value: "17:00:00" },
        { label: "17:30:00", value: "17:30:00" },
        { label: "18:00:00", value: "18:00:00" },
        { label: "18:30:00", value: "18:30:00" },
        { label: "19:00:00", value: "19:00:00" },
        { label: "19:30:00", value: "19:30:00" },
        { label: "20:00:00", value: "20:00:00" },
        { label: "20:30:00", value: "20:30:00" },
        { label: "21:00:00", value: "21:00:00" },
        { label: "21:30:00", value: "21:30:00" },
        { label: "22:00:00", value: "22:00:00" },
        { label: "22:30:00", value: "22:30:00" },
        { label: "23:00:00", value: "23:00:00" },
        { label: "23:30:00", value: "23:30:00" }
    ];
};

/* Utility method to extract location properties from a Google maps object */
export const getLocationFromGoogleMapsObject = addressComponents => {
    const location = {
        city: "",
        state: ""
    };

    if (Array.isArray(addressComponents) && addressComponents.length > 0) {
        addressComponents.forEach((item, index) => {
            // Get city
            if (item.types.includes("locality")) {
                location.city = item.long_name;
            }
            // Get state
            if (item.types.includes("administrative_area_level_1")) {
                location.state = item.long_name;
            }
        });
    }

    return location;
};

export const createOption = label => ({
    label,
    value: label
});

/* Utility method to extract period type */
export const getPeriodType = () => {
    return [
        {
            label: "Weekly",
            value: "Weekly"
        },
        {
            label: "Fortnightly",
            value: "Fortnightly"
        },
        {
            label: "Monthly",
            value: "Monthly"
        },
        {
            label: "Quarterly",
            value: "Quarterly"
        },
        {
            label: "Half-Yearly",
            value: "Half-Yearly"
        },
        {
            label: "Yearly",
            value: "Yearly"
        }
    ];
};

/* Utility method to convert a date object into the expected format  */
export const formatDate = date => {
    let dateObject = new Date(date);
    let month = (dateObject.getMonth() + 1).toString();
    let day = dateObject.getDate().toString();
    let year = dateObject.getFullYear().toString();

    if (month.length < 2) {
        month = "0" + month;
    }
    if (day.length < 2) {
        day = "0" + day;
    }
    return [year, month, day].join("-");
};

/* Utility method to convert a date object into a human readable string  */
export const convertToReadableDate = date => {
    return new Date(date).toDateString();
};

/* Utility method to convert a time object into a human readable string  */
export const convertReadableTime = time => {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
        // If time format correct
        time = time.slice(1); // Remove full string match value
        time[5] = +time[0] < 12 ? "am" : "pm"; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
};
